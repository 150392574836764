import React, { useState, useEffect, useRef, useCallback  } from 'react';
import fetch from "node-fetch";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//import { styled } from '@mui/material/styles';

const RoomDetail = () => {
  const [name, setName] = useState('');
  const [roomName, setRoomName] = useState('');
  const [roomExists, setRoomExists] = useState(null);
  const [hasJoined, setHasJoined] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const { id: roomId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const websocket = useRef(null);
  const [campaignId, setCampaignId] = useState(''); // New state for campaign ID
  const [campaignSet, setCampaignSet] = useState(false); // New state to track if the campaign is set
  const [isCampaignIdValid, setIsCampaignIdValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [setting, setSetting] = useState('');
  const [style, setStyle] = useState('');
  const [focus, setFocus] = useState('');
  const [levelRange, setLevelRange] = useState('');
  const [email, setEmail] = useState('');
  const [showDisconnectedMessage, setShowDisconnectedMessage] = useState(false); // New state for disconnection message
  const messageAreaRef = useRef(null);
  const [isMessageWaiting, setIsMessageWaiting] = useState(false);
  const [characters, setCharacters] = useState([]); // State for list of characters
  const [selectedCharacter, setSelectedCharacter] = useState(''); // State for the selected character
  const [characterDetails, setCharacterDetails] = useState(''); // State for character details
  const [showCreateCharacterModal, setShowCreateCharacterModal] = useState(false);
  const [newCharacter, setNewCharacter] = useState({
    name: '',
    race: '',
    sex: '',
    class: '',
    passivePerception: '',
    passiveInsight: '',
    level: '',
    background: ''
  });
  const [isCharFormValid, setIsCharFormValid] = useState(false);
  const [isCampaignMessageReceived, setIsCampaignMessageReceived] = useState(false);
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');


  useEffect(() => {
    const userNameFromParams = searchParams.get('name');
    if (userNameFromParams) {
      setName(userNameFromParams);
    }

    const checkRoomExistence = async () => {
      try {
        const response = await fetch(`https://d5libesx21.execute-api.eu-west-2.amazonaws.com/V1/exists?roomId=${roomId}`);
        const data = await response.json();

        if (response.ok && data.exists) {
          setRoomExists(true);
          setRoomName(data.roomName);
        } else {
          setRoomExists(false);
          setTimeout(() => navigate('/'), 3000);
        }
      } catch (error) {
        console.error('Error checking room:', error);
        setRoomExists(false);
        setTimeout(() => navigate('/'), 3000);
      }
    };

    checkRoomExistence();
  }, [roomId, navigate, searchParams]);

 const handleJoinRoom = useCallback(() => {
    if (name) {
      console.log("Making a socket");
      setHasJoined(true);
      const websocketUrl = `wss://v2uoycwdac.execute-api.eu-west-2.amazonaws.com/V1/?roomId=${roomId}&userName=${encodeURIComponent(name)}`;
      websocket.current = new WebSocket(websocketUrl);

      websocket.current.onopen = () => {
        console.log('WebSocket Connected');
        
        if(websocket.current)
        {
          websocket.current.send(JSON.stringify({ action: 'connectedUsers', roomId: roomId }));
          websocket.current.send(JSON.stringify({ action: 'getCampaignId', roomId: roomId }));
        }
      };

      websocket.current.onmessage = (event) => {
        console.log('Message from server:', event);
        const data = JSON.parse(event.data);
        //console.log('Data: ', data.action);
        switch (data.action) {
          case "connectedUsers":
            console.log("Connected Users -", data.body.connectedUsers);
            setConnectedUsers(data.body.connectedUsers);
            break;
          case "characters":
            const charactersData = data.body.characters.map(item => {
              const characterDetails = JSON.parse(item.details);
               console.log("Char Details -", characterDetails);
                return {
                  connectionId: item.connectionId,
                  id: item.characterId,
                  name: characterDetails.name,
                  race: characterDetails.race,
                  class: characterDetails.class,
                  sex: characterDetails.sex,
                  passivePerception: characterDetails.passivePerception,
                  passiveInsight: characterDetails.passiveInsight,
                  level: characterDetails.level,
                  owned: item.owned
              };
            });
            
            console.log("Chars -", charactersData);
            setCharacters(charactersData);
            
            const ownedCharacter = charactersData.find(character => character.owned);
            console.log("Owned Char -", ownedCharacter);
            if (ownedCharacter) {
                setCharacterDetails(ownedCharacter.id);
            }
            else
            {
               console.log("Blanking Char");
               setCharacterDetails('');
            }
            break;
          case "receivedMessage":
            setMessages(prev => {
            const newMessage = {
              user: data.body.user,
              character: data.body.character,
              message: data.body.message
            };
          
            const newMessages = [...prev, newMessage];
          
            // Calculate the total length of the messages
            let totalLength = newMessages.reduce((acc, msg) => acc + (msg.user.length + (msg.character ? msg.character.length + 3 : 0) + msg.message.length + 2), 0);
          
            // Remove messages from the start until the total length is under the limit
            while (totalLength > 20000 && newMessages.length > 0) {
              const removedMessage = newMessages.shift();
              totalLength -= (removedMessage.user.length + (removedMessage.character ? removedMessage.character.length + 3 : 0) + removedMessage.message.length + 2);
            }
          
            return newMessages;
          });

            setIsMessageWaiting(true);
           /* console.log("Movin Scroll");
            if (messageAreaRef.current) {
              console.log("Movin Scroll");
                messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
              }*/
            break;
           case "gptReceivedMessage":
            setIsMessageWaiting(false);
            setMessages(prev => {
              const newMessage = {
                user: data.body.user,
                message: data.body.message
              };
            
              const newMessages = [...prev, newMessage];
            
              // Calculate the total length of the messages
              let totalLength = newMessages.reduce((acc, msg) => acc + (msg.user.length + (msg.character ? msg.character.length + 3 : 0) + msg.message.length + 2), 0);
            
              // Remove messages from the start until the total length is under the limit
              while (totalLength > 20000 && newMessages.length > 0) {
                const removedMessage = newMessages.shift();
                totalLength -= (removedMessage.user.length + (removedMessage.character ? removedMessage.character.length + 3 : 0) + removedMessage.message.length + 2);
              }
            
              return newMessages;
            });
           /* console.log("Movin Scroll");
            if (messageAreaRef.current) {
              console.log("Movin Scroll");
                messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
              }*/
            break;
          case "campaignIdReceived":
            setIsCampaignMessageReceived(true);
            setCampaignId(data.body.campaignId);
            if(data.body.campaignId === '')
            {
              setCampaignSet(false);
              setIsCampaignIdValid(false);
              setCharacters([]);
              setSelectedCharacter('');
              setCharacterDetails('');
              handleCloseCreateCharacterModal();
              websocket.current.send(JSON.stringify({ action: 'connectedUsers', roomId: roomId }));
            }
            else
            {
              setCampaignSet(true);
              setIsCampaignIdValid(true);
              handleCloseModal();
              websocket.current.send(JSON.stringify({ action: 'getCharacters', campaignId: data.body.campaignId }));
            }
            // Handle campaign details response
            // You will populate this later
            break;
          default:
            console.log("Unknown Message", data);
              setIsMessageWaiting(false);
            break;
        }
      };

      websocket.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

       websocket.current.onclose = (event) => {
        console.log('WebSocket Disconnected:', event);
        setShowDisconnectedMessage(true); // Show disconnection message
        setTimeout(() => {
          navigate('/'); // Navigate back to index page after 2 seconds
        }, 2000);
      };
    }
  }, [name, roomId, navigate]);

  useEffect(() => {
    if (name && roomName && roomExists) {
      handleJoinRoom();
    }
  }, [name, roomName, roomExists, handleJoinRoom]);

  useEffect(() => {
  //console.log("Moving Scroll");
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [messages]);
  
  const handleSendMessage = () => {
    //setMessages(prev => [...prev, `${name}: ${inputValue}`]);
    
    if (inputValue && websocket.current) {
      
      const characterName = characters.find(character => character.id === characterDetails)?.name || 'Unknown';
      
      console.log("Sending Message from - ", characterName);
      
      websocket.current.send(JSON.stringify({
        action: 'sendMessage',
        userName: name, // assuming 'name' holds the username of the person sending the message
        characterName: characterName,
        message: inputValue, // the message text
        roomId: roomId, // assuming 'roomId' is the ID of the current room
        campaignId: campaignId
      }));
      setIsMessageWaiting(true);
      setInputValue('');
    }
  };
  
  const handleSetCampaignId = () => {
    console.log("Is it me?", isCampaignIdValid);
    
    if (isCampaignIdValid && websocket.current) {
       websocket.current.send(JSON.stringify({
        action: 'setCampaignId',
        campaignId: campaignId,
        roomId: roomId
      }));
    }
  };
  
  const handleRemoveCampaignId = () => {
     if(websocket.current){
       console.log("Sending remove");
       websocket.current.send(JSON.stringify({
          action: 'setCampaignId',
          campaignId: '',
          oldCampaignId: campaignId,
          roomId: roomId
       }));
     }
  };
  
  const validateUUID = (uuid) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
  };

  const handleCampaignIdChange = (e) => {
    const uuid = e.target.value;
    setCampaignId(uuid);
    setIsCampaignIdValid(validateUUID(uuid));
  };

  
  const handleJoinClick = () => {
    setName(inputValue);
    setInputValue('');
    // ... additional logic for joining the room ...
  };
  
   // Function to open the modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCreateCampaign = () => {
    // Logic to handle campaign creation
    handleCloseModal();
    
     if (websocket.current) {
       console.log("Creating campaign");
      websocket.current.send(JSON.stringify({
        action: 'createCampaign',
        details: {
          setting: setting,
          style: style,
          focus: focus,
          levelRange: levelRange
        },
        roomId: roomId,
        recoveryEmail: email
      }));
      
      setIsMessageWaiting(true);
    }
    // Send request with setting, style, focus, and levelRange
  };
  
  const handleCharacterChange = (event) => {
    setSelectedCharacter(event.target.value);
  };
  
  const handleSelectCharacter = () => {
    // Logic to fetch and set character details based on selectedCharacter
    //const details = characters.find(char => char.id === selectedCharacter);
    //setCharacterDetails(details);
    console.log("Set Char - ", selectedCharacter );
     if (websocket.current) {
       console.log("Selecting Character");
      websocket.current.send(JSON.stringify({
        action: 'setCharacter',
        roomId: roomId,
        campaignId: campaignId,
        characterId: selectedCharacter
      }));
    }
  };
  
  const handleRemoveCharacter = () => {
    // Logic to fetch and set character details based on selectedCharacter
    //const details = characters.find(char => char.id === selectedCharacter);
    //setCharacterDetails(details);
    console.log("Remove Char - ", selectedCharacter );
     if (websocket.current) {
       console.log("Removing Character");
      websocket.current.send(JSON.stringify({
        action: 'setCharacter',
        roomId: roomId,
        campaignId: campaignId
      }));
    }
  };
  
  const handleCreateCharacter = () => {
    // Logic for creating a new character
    setShowCreateCharacterModal(true);
  };
  
   const handleNewCharacterChange = (prop) => (event) => {
        const value = event.target.value;
        setNewCharacter(prevState => ({ ...prevState, [prop]: value }));
    };
    
    useEffect(() => {
        // Validation logic
        const isValid = newCharacter.name.trim() !== '' &&
                        newCharacter.race.trim() !== '' &&
                        newCharacter.sex.trim() !== '' &&
                        newCharacter.class.trim() !== '' &&
                        newCharacter.passivePerception.trim() !== '' && 
                        newCharacter.passiveInsight.trim() !== '' &&
                        newCharacter.level.trim() !== '' &&
                        !isNaN(newCharacter.passivePerception) && 
                        !isNaN(newCharacter.passiveInsight) &&
                        !isNaN(newCharacter.level) &&
                        newCharacter.background.length <= 2000;
    
        setIsCharFormValid(isValid);
    }, [newCharacter]); // Depend on newCharacter state
  
  const handleSaveCharacter = () => {
  // Validate the inputs
    if (!newCharacter.name || !newCharacter.sex || !newCharacter.race || !newCharacter.class ||
        newCharacter.passivePerception === '' || newCharacter.passiveInsight === '' ||
        newCharacter.level === '' || isNaN(newCharacter.passivePerception) || 
        isNaN(newCharacter.passiveInsight) || isNaN(newCharacter.level)) {
      alert('Please fill out all required fields with valid data.');
      return;
    }
  
    // Save the character
    // Add your logic to save the character
  
    setShowCreateCharacterModal(false);
    
     if (websocket.current) {
       console.log("Creating character");
      websocket.current.send(JSON.stringify({
        action: 'createCharacter',
        details: {
          name: newCharacter.name,
          race: newCharacter.race,
          sex: newCharacter.sex,
          class: newCharacter.class,
          passivePerception: newCharacter.passivePerception,
          passiveInsight: newCharacter.passiveInsight,
          level: newCharacter.level,
          background: newCharacter.background
        },
        roomId: roomId,
        campaignId: campaignId
      }));
    }
  };
  
  const handleCloseCreateCharacterModal = () => {
    setShowCreateCharacterModal(false);
  };
  
  const handleIntegerChange = (prop) => (event) => {
    const value = event.target.value;
    // Allow only integer values
    if (!value || /^\d+$/.test(value)) {
      setNewCharacter({ ...newCharacter, [prop]: value });
    }
  };
      
   const copyToClipboard = () => {
    const roomLink = `${window.location.origin}/room/${roomId}`;
    navigator.clipboard.writeText(roomLink).then(() => {
      // Optional: Show some feedback to the user that the link has been copied
      console.log('Room link copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy room link: ', err);
    });
  };
  


const RenderMessage = ({ msg }) => {
  const userAndCharacter = `${msg.user}${msg.character ? ` (${msg.character})` : ''}`;
  const messageLines = msg.message.split('\n');

  // Log directly during render
 // console.log('RenderMessage - userAndCharacter:', userAndCharacter);
  //console.log('RenderMessage - messageLines:', messageLines);

  return (
    <>
      <strong>{userAndCharacter}</strong>:{" "}
      {messageLines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== messageLines.length - 1 && <br />}
        </React.Fragment>
      ))}
      <br /><br />
    </>
  );
};



  
  const isValidEmail = (email, allowBlank = true) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (allowBlank && email === '') {
        return true;
    }
    return re.test(String(email).toLowerCase());
};

  
   const handleOpenRecoveryModal = () => {
    setShowRecoveryModal(true);
   };
  
   const handleCloseRecoveryModal = () => {
    setShowRecoveryModal(false);
   };
  
 const handleRecoverCampaigns = async () => {
  
  handleCloseRecoveryModal();
  const url = 'https://hqmtorwld6.execute-api.eu-west-2.amazonaws.com/V1/recover';
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email })
    };
  
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
  
      if (response.ok) {
        console.log('Recovery email sent successfully', data);
        setSnackbarMessage('Campaigns related to your email address (if any) have been sent to you.');
        setSnackbarSeverity('success');
      } else {
        console.error('Failed to send recovery email', data);
         setSnackbarMessage('We were unable to send you campaigns related to that email address.');
      setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error while sending recovery email', error);
       setSnackbarMessage('We were unable to send you campaigns related to that email address.');
        setSnackbarSeverity('error');
    }
    
    setSnackbarOpen(true);
  }
  
  const handleKeyDown = (event) => {
  // Check if Enter key is pressed and the send button conditions are met
  if (event.key === 'Enter' && inputValue.trim() && !isMessageWaiting && campaignSet && characterDetails) {
    event.preventDefault(); // Prevent default to avoid newline in TextField
    handleSendMessage();
  }
};

  if (roomExists === null) {
    return (
      <Box textAlign="center" class="fullMessage">
        <Typography variant="h6">Connecting to Room...</Typography>
      </Box>
    );
  }

  if (!roomExists) {
    return (
      <Box textAlign="center" class="fullMessage">
        <Typography variant="h6">Room does not exist. Redirecting to the home page...</Typography>
      </Box>
    );
  }

  if (!hasJoined) {
    return (
      <Box class="fullMessage">
        <Typography variant="h5">Enter Your Name to Join Room {roomName || roomId}</Typography>
        <TextField
          type="text"
          placeholder="Enter your name"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          margin="normal"
          fullWidth
          className="textField"
        />
        <Button variant="contained" onClick={handleJoinClick}>Join Room</Button>
      </Box>
    );
  }
  
  if (showDisconnectedMessage) {
    return (
      <Box textAlign="center" class="fullMessage">
        <Typography variant="h6">Disconnected from the room. Redirecting to the home page...</Typography>
      </Box>
    );
  }
  
  return (
  <Container className="room-container">
      <Box className="chat-section">
        {/* Chat messages */}
       <Box className="message-area" ref={messageAreaRef}>
        {messages.map((msg, index) => (
          <Typography key={index}>
            <RenderMessage msg={msg} />
          </Typography>
        ))}
      </Box>


        {/* Send message area */}
        <TextField
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type a message"
          margin="normal"
          fullWidth
          className="textField"
          disabled={!isCampaignMessageReceived}
          onKeyDown={handleKeyDown} // Attach the event handler here
        />
        <Button 
            onClick={handleSendMessage} 
            variant="outlined"
            disabled={!inputValue.trim() || isMessageWaiting || !campaignSet || !characterDetails}
          >
            {isMessageWaiting ? <CircularProgress size={24} /> : "Send Message"}
      </Button>
      </Box>

      <Box className="sidebar-section">
        {/* User and room info */}
        <Box className="room-details-box">
        <Box className="room-details">
        <Typography>Welcome, {name}. Invite others to {roomName}</Typography>
         <Typography className="room-link">&nbsp;
         <img className="copyToClipboard"
          src="/assets/copyToClipboardWhite.png" 
          alt="Copy to Clipboard" 
          onClick={copyToClipboard} 
          style={{ cursor: 'pointer' }} // Optional for mouse pointer
        />
        </Typography>
        </Box>
        <Box className="connected-users">
        <Typography>Users:</Typography>
        <List>
          {connectedUsers.map((user, index) => (
              <ListItem key={index}>
                  {user.userName} 
                  {user.characterDetails && (
                      <span>
                          : {user.characterDetails.name}, {user.characterDetails.race} {user.characterDetails.class}
                      </span>
                  )}
              </ListItem>
          ))}
      </List>
        </Box>
        </Box>
         {!campaignSet ? (
        <Box className="campaign-box">
          <TextField
            label="Set campaign ID"
            value={campaignId}
            onChange={handleCampaignIdChange}
            margin="normal"
            disabled={campaignSet || !isCampaignMessageReceived}
            className="textField"
          />
          <Button onClick={handleSetCampaignId} disabled={!isCampaignIdValid}>
            Set Campaign ID
          </Button>
          <Button variant="contained" onClick={handleOpenModal} disabled={!isCampaignMessageReceived}>
            Create New Campaign
          </Button>
          <Button variant="contained" onClick={handleOpenRecoveryModal} disabled={!isCampaignMessageReceived}>
            Recover Campaigns
          </Button>
        </Box>
      ) : (
        <Box className="campaign-box">
          <Typography variant="h6">Campaign ID: {campaignId}</Typography>
          <Button variant="contained" onClick={handleRemoveCampaignId}>
            Remove Campaign ID
          </Button>
        </Box>
      )}
      
      {campaignSet && (
        <Box className="character-section">
          {!characterDetails && (
            <>
              <Typography variant="h6">Character Selection</Typography>
              <TextField
                select
                label="Select a Character"
                value={selectedCharacter}
                onChange={handleCharacterChange}
                fullWidth
                className="textField"
                disabled={characters.length === 0}
              >
                 {characters
                  .filter(character => !character.connectionId) // Filter characters without connectionId
                  .map((character) => (
                    <MenuItem key={character.id} value={character.id} style={{ color: '#000000' }}>
                      {character.name + ", " + character.race + " " + character.class}
                    </MenuItem>
                  ))}
              </TextField>
              <Button
                onClick={handleSelectCharacter}
                disabled={!selectedCharacter}
              >
                Select Character
              </Button>
              <Button onClick={handleCreateCharacter}>
                Create Character
              </Button>
            </>
          )}
          {characterDetails && (
            <Box className="character-details">
            <Typography variant="h6">Character Details</Typography>
                <div>
                    {characters.filter(character => character.id === characterDetails).map((character, index) => (
                        <div key={index}>
                            <Typography>Name: {character.name}</Typography>
                            <Typography>Race: {character.race}</Typography>
                            <Typography>Class: {character.class}</Typography>
                            <Typography>Sex: {character.sex}</Typography>
                            <Typography>Level: {character.level}</Typography>
                        </div>
                    ))}
                </div>
                <Button onClick={handleRemoveCharacter}>
                  Remove Character
                </Button>
        </Box>
          )}
        </Box>
      )}


      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Create a New Campaign</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the following details to create a new campaign. Leave a field blank to leave it up to the AI.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="setting"
            label="Setting (e.g., Forgotten Realms, Dark Sun etc)"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setSetting(e.target.value)} // use setSetting here
            className="textField"
          />
          <TextField
            autoFocus
            margin="dense"
            id="style"
            label="Style (e.g., Exploration, Mystery, Political Intrigue, etc)"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setStyle(e.target.value)} // use setSetting here
            className="textField"
          />
          <TextField
            autoFocus
            margin="dense"
            id="focus"
            label="Focus (e.g., Narrative, Combat, Mixed, etc)"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setFocus(e.target.value)} // use setSetting here
            className="textField"
          />
          <TextField
            autoFocus
            margin="dense"
            id="levelRange"
            label="Level Range (e.g., 1-5)"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setLevelRange(e.target.value)} // use setSetting here
            className="textField"
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Recovery Email"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)} // use setSetting here
            className="textField"
          />
          {/* Add other TextFields for Style, Focus, Level Range */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleCreateCampaign} disabled={!isValidEmail(email)}>
            Create Campaign
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={showCreateCharacterModal} onClose={handleCloseCreateCharacterModal}>
        <DialogTitle>Create a New Character</DialogTitle>
        <DialogContent>
          {/* Text fields for Name, Sex, Class */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.name}
            onChange={handleNewCharacterChange('name')}
            className="textField"
          />
           <TextField
            autoFocus
            margin="dense"
            id="race"
            label="Race"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.race}
            onChange={handleNewCharacterChange('race')}
            className="textField"
          />
         <TextField
            autoFocus
            margin="dense"
            id="sex"
            label="Sex"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.sex}
            onChange={handleNewCharacterChange('sex')}
            className="textField"
          />
          <TextField
            autoFocus
            margin="dense"
            id="class"
            label="Class"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.class}
            onChange={handleNewCharacterChange('class')}
            className="textField"
          />
          <TextField
            margin="dense"
            id="passivePerception"
            label="Passive Perception"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.passivePerception}
            onChange={handleIntegerChange('passivePerception')}
            className="textField"
          />
          <TextField
            margin="dense"
            id="passiveInsight"
            label="Passive Insight"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.passiveInsight}
            onChange={handleIntegerChange('passiveInsight')}
            className="textField"
          />
          <TextField
            margin="dense"
            id="level"
            label="Level"
            type="text"
            fullWidth
            variant="standard"
            value={newCharacter.level}
            onChange={handleIntegerChange('level')}
            className="textField"
          />
          <TextField
            margin="dense"
            id="background"
            label="Background"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={4}
            inputProps={{ maxLength: 2000 }}
            value={newCharacter.background}
            onChange={handleNewCharacterChange('background')}
            className="textField"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateCharacterModal}>Cancel</Button>
          <Button onClick={handleSaveCharacter} disabled={!isCharFormValid}>Save Character</Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={showRecoveryModal} onClose={handleCloseRecoveryModal}>
        <DialogTitle>Create a New Character</DialogTitle>
        <DialogContent>
          {/* Text fields for Name, Sex, Class */}
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email - This will not be stored"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)} // use setSetting here
            className="textField"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRecoveryModal}>Cancel</Button>
           <Button onClick={handleRecoverCampaigns} disabled={!isValidEmail(email, false)}>
            Recover Campaigns
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
       <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
       </Alert>
    </Snackbar>
    </Container>
  );
};

export default RoomDetail;

